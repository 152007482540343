/*     320px — 480px : les mobiles
    481px — 768px : iPads, tablettes
    769px — 1024px : petits écrans, ordinateurs portables
    1025px — 1200px : ordinateurs de bureau, écrans larges
    1201px et plus —  écrans extra larges, TV
     */

.installer-header-container__clz {
    background-color: rgb(26, 21, 21);
}
.installer-container__clz {
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 50px;
    row-gap: 21px;
    display: flex;
}

.invalid-icon__clz {
    padding-right: 5px;
}

.valid-icon__clz {
    cursor: pointer;
    padding-right: 5px;
}
.installer-download__clz {
    display: flex;
    flex-wrap: wrap;
}
.container__clz {
    display: flex;
    justify-content: unset;
}

.installer-cards {
    padding-bottom: 100px;
    padding-left: 26px;
    padding-right: 27px;
}

.installer-card-header__clz img {
    display: block;
    border: 0;
    width: 100%;
    height: 259px;
}

.installer-global-title__clz {
    text-align: center;
    padding-top: 22px;
    padding-right: 27%;
    padding-left: 28%;
    padding-bottom: 40px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .installer-global-title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 8%;
        padding-left: 6%;
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .installer-global-title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 4%;
        padding-left: 4%;
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .installer-global-title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 4%;
        padding-left: 2%;
        padding-bottom: 40px;
    }
}

.installer-firstIcon:hover {
    transform: scale(1.5);
    transition: all 0.2s ease-in-out;
}
.installer-etk-title__clz {
    text-align: center;
    padding-top: 22px;
    font-size: 55px;
    color: #2f2b2b;
    font-weight: bold;
    font-family: Bebas Neue !important;
}
.installer-opacity-title__clz {
    opacity: 0.7;
    font-style: normal;
    font-display: swap;
    font-weight: bold;
    font-size: 45px;
    font-family: Bebas Neue !important;
    font-size: 55px;
}
.installer-title__clz {
    color: #2f2b2b;
    text-transform: uppercase;
    font-size: 65px;
    line-height: 65px;
    font-weight: bold;
    font-family: "Bebas Neue";
    letter-spacing: 5px;
    padding-bottom: 40px;
}

.installer-outer_clz {
    position: relative;
    background: rgb(0, 0, 0);
    height: 350px;
    width: 1130px;
    overflow: hidden;
    display: flex;
    border-radius: 23px;
}
.installer-image__clz .img {
    position: absolute;
    top: 1px;
    right: 0px;
    z-index: 0;
    animation-delay: 0.5s;
    width: 479px;
    height: 348px;
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
    .installer-image__clz .img {
        position: absolute;
        top: 1px;
        right: 0px;
        z-index: 0;
        animation-delay: 0.5s;
        width: 479px;
        height: 348px;
        opacity: 0.5;
    }
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .installer-image__clz .img {
        display: none;
    }
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .installer-divider {
        display: none;
    }
}

.installer-divider {
    padding: 5px;
}

.installer-content__clz {
    animation-delay: 0.3s;
    position: absolute;
    left: 20px;
    z-index: 3;
}

.installer-h1-title__clz {
    color: rgb(234, 228, 228);
    text-align: start;
    /* padding-top: 36px; */
    font-size: 38px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: Bebas Neue !important;
    margin-bottom: 4rem !important;
}

.installer-p-title__clz {
    color: rgb(234, 228, 228);
    text-align: start;
    padding-top: 0px;
    font-size: 27px;
    padding-bottom: 130px;
    font-family: Segoe UI !important;
}

.installer-download-title__clz {
    color: rgb(234, 228, 228);
    text-align: start;
    font-size: 20px;
    padding-top: 9px;
    padding: 5px;
    font-family: Segoe UI !important;
}

.installer-footer-title__clz {
    color: #fff;
    font-size: 40px;
}
.installer-cart-btn_clz {
    color: rgb(234, 228, 228);
    text-align: start;
    font-size: 20px;
    padding-top: 10px;
    padding: 5px;
    text-decoration-line: underline;
    font-family: segoe ui;
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .installer-cart-btn_clz {
        font-size: 20px;
        color: rgb(234, 228, 228);
        text-align: start;
        padding-top: 10px;
        padding-left: 16px;
        text-decoration-line: underline;
    }
}

.dropdown-menu {
    min-width: 5rem !important;
}
