@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
/*     320px — 480px : les mobiles
    481px — 768px : iPads, tablettes
    769px — 1024px : petits écrans, ordinateurs portables
    1025px — 1200px : ordinateurs de bureau, écrans larges
    1201px et plus —  écrans extra larges, TV
     */

.installer-header-container__clz {
    background-color: rgb(26, 21, 21);
}
.installer-container__clz {
    flex-wrap: wrap;
    justify-content: center;
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
    grid-row-gap: 21px;
    row-gap: 21px;
    display: flex;
}

.invalid-icon__clz {
    padding-right: 5px;
}

.valid-icon__clz {
    cursor: pointer;
    padding-right: 5px;
}
.installer-download__clz {
    display: flex;
    flex-wrap: wrap;
}
.container__clz {
    display: flex;
    justify-content: unset;
}

.installer-cards {
    padding-bottom: 100px;
    padding-left: 26px;
    padding-right: 27px;
}

.installer-card-header__clz img {
    display: block;
    border: 0;
    width: 100%;
    height: 259px;
}

.installer-global-title__clz {
    text-align: center;
    padding-top: 22px;
    padding-right: 27%;
    padding-left: 28%;
    padding-bottom: 40px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .installer-global-title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 8%;
        padding-left: 6%;
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .installer-global-title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 4%;
        padding-left: 4%;
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .installer-global-title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 4%;
        padding-left: 2%;
        padding-bottom: 40px;
    }
}

.installer-firstIcon:hover {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    transition: all 0.2s ease-in-out;
}
.installer-etk-title__clz {
    text-align: center;
    padding-top: 22px;
    font-size: 55px;
    color: #2f2b2b;
    font-weight: bold;
    font-family: Bebas Neue !important;
}
.installer-opacity-title__clz {
    opacity: 0.7;
    font-style: normal;
    font-display: swap;
    font-weight: bold;
    font-size: 45px;
    font-family: Bebas Neue !important;
    font-size: 55px;
}
.installer-title__clz {
    color: #2f2b2b;
    text-transform: uppercase;
    font-size: 65px;
    line-height: 65px;
    font-weight: bold;
    font-family: "Bebas Neue";
    letter-spacing: 5px;
    padding-bottom: 40px;
}

.installer-outer_clz {
    position: relative;
    background: rgb(0, 0, 0);
    height: 350px;
    width: 1130px;
    overflow: hidden;
    display: flex;
    border-radius: 23px;
}
.installer-image__clz .img {
    position: absolute;
    top: 1px;
    right: 0px;
    z-index: 0;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    width: 479px;
    height: 348px;
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
    .installer-image__clz .img {
        position: absolute;
        top: 1px;
        right: 0px;
        z-index: 0;
        -webkit-animation-delay: 0.5s;
                animation-delay: 0.5s;
        width: 479px;
        height: 348px;
        opacity: 0.5;
    }
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .installer-image__clz .img {
        display: none;
    }
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .installer-divider {
        display: none;
    }
}

.installer-divider {
    padding: 5px;
}

.installer-content__clz {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
    position: absolute;
    left: 20px;
    z-index: 3;
}

.installer-h1-title__clz {
    color: rgb(234, 228, 228);
    text-align: start;
    /* padding-top: 36px; */
    font-size: 38px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: Bebas Neue !important;
    margin-bottom: 4rem !important;
}

.installer-p-title__clz {
    color: rgb(234, 228, 228);
    text-align: start;
    padding-top: 0px;
    font-size: 27px;
    padding-bottom: 130px;
    font-family: Segoe UI !important;
}

.installer-download-title__clz {
    color: rgb(234, 228, 228);
    text-align: start;
    font-size: 20px;
    padding-top: 9px;
    padding: 5px;
    font-family: Segoe UI !important;
}

.installer-footer-title__clz {
    color: #fff;
    font-size: 40px;
}
.installer-cart-btn_clz {
    color: rgb(234, 228, 228);
    text-align: start;
    font-size: 20px;
    padding-top: 10px;
    padding: 5px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    font-family: segoe ui;
}

@media screen and (min-width: 0px) and (max-width: 576px) {
    .installer-cart-btn_clz {
        font-size: 20px;
        color: rgb(234, 228, 228);
        text-align: start;
        padding-top: 10px;
        padding-left: 16px;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
    }
}

.dropdown-menu {
    min-width: 5rem !important;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

#nprogress .bar,
#nprogress .spinner {
    z-index: 999999 !important;
}
.password-toggle__2 {
    position: absolute;
    padding-top: 18px !important;
    right: 26px;
    opacity: 0.6;
}
.password-toggle__1 {
    position: absolute;
    right: 26px;
    opacity: 0.6;
}
.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}

@media (max-width: 576px) {
    .login-logo__clz {
        margin-left: 23vw;
        margin-top: 2vh;
        width: 35vh;
    }
    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }

    .etk-logo__clz {
        top: 5%;
        position: absolute;
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
                clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
        width: 100%;
        height: 100vh;
    }
    .video {
        width: auto !important;
        height: 100vh !important;
    }
    .login-card {
        top: 60% !important;
        left: 50% !important;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .login-logo__clz {
        margin-left: 28vw;
        margin-top: 2vh;
        margin-bottom: 4vh;
        width: 35vh;
    }
    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }
    .etk-logo__clz {
        width: 80%;
        top: 2%;
        left: 10%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
                clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
        width: 100%;
        height: 100vh;
    }
    .video {
        width: auto !important;
        height: 100vh !important;
    }
    .login-card {
        top: 55% !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .login-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }
    .video {
        width: auto !important;
        height: 100% !important;
    }
    .login-card {
        top: 60% !important;
        left: 55% !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .login-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }
    .video {
        width: auto;
        height: 100vh;
    }
    .login-card {
        top: 55% !important;
        left: 55% !important;
    }
}

@media (min-width: 1200px) {
    .login-logo__clz {
        margin-left: 4vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 10vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
        width: 30%;
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 40vw;
    }
    .video {
        width: 120%;
        height: auto;
    }

    .login-card {
        left: 60% !important;
    }
}

.login-btn__clz {
    box-shadow: 0px 15px 20px 7px #bfbdbd !important;
}

.login-btn__clz:hover {
    background-color: white !important;
    color: black !important;
}

.login-card {
    font-family: "Poppins";
    left: 60% !important;
    top: 50% !important;
    width: 30% !important;
    height: 47% !important;
}

.login-card h3 {
    font-size: 35px;
}

.login-card .form-group label {
    font-size: 20px;
}

.password-toggle__clz {
    position: absolute;
    padding-top: 42px;
    right: 35px;
    opacity: 0.6;
}

.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}
.submit-button__clz {
    position: fixed;
    bottom: 0;
    margin-bottom: 50px;
    margin-left: 31%;
}

.header-container__clz {
    background-color: rgb(26, 21, 21);
    display: flex;
    justify-content: space-between;
    padding-right: 59px;
    padding-left: 67px;
}

.footer__clz {
    margin-left: 65px !important;
    display: flex;
    position: fixed;
}

.footer {
    position: fixed;
    right: 0;
    color: #212529;
    left: 250px !important;
    background-color: #efeff5;
    display: flex;
}

ul.sub-menu > li.mm-active > a.active {
    border-left: 3px solid white;
    background-color: #ecefef24;
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
    .global-footer__clz {
        margin-top: auto;
        background-color: rgb(253, 253, 253);
        font-size: 18px;
        padding-bottom: 12px;
    }
}

@media screen and (min-width: 1025px) and (max-width: 2700px) {
    .global-footer__clz {
        margin-top: auto;
        background-color: rgb(253, 253, 253);
        font-size: 18px;
        padding-bottom: 12px;
        padding-left: 254px;
        padding-right: 254px;
    }
}

.footer-text__clz {
    grid-column-gap: 33px;
    -webkit-column-gap: 33px;
            column-gap: 33px;
    display: flex;
    color: #533c3c;
    padding-left: 16px;
    font-family: arial !important;
}
.footer-text__clz a {
    color: #533c3c;
}
.divider__clz {
    padding-bottom: 9px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.dropdown-menu {
    min-width: 5rem !important;
}

.language-switch {
    top: 5px !important;
    left: 12px !important;
}

